export const THUMBNAIL = '?x-oss-process=image/resize,w_250';
export const THUMBNAIL_MAX = '?x-oss-process=image/resize,w_500';
export const THUMBNAIL_ORIGIN = '?x-oss-process=image/resize,w_2560';

export const THUMBNAIL_VEDIOCOVER = '?x-oss-process=video/snapshot,t_0,f_jpg,w_800,h_600,m_fast'
export const VUE_APP_MAP_TILELAYER_URL = 'https://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}&mapType=normal' // GMap 服务器地址&配置
export const mapKey = '50963b3c752531b0f7f3524cd2e40b9a' // 高德地图开发者key
export const defaultAuth = ['home', 'home_dashboard']
export const mapUrl = (params) => {
    let url = 'https://restapi.amap.com/v3/place/text'
    const baseParams = {
        key: '50963b3c752531b0f7f3524cd2e40b9a',
        s: 'rsv3',
        language: 'zh_cn',
        output: 'json',
        platform: 'JS',
        logversion: '2.0',
        csid: 'B50A214A-1618-4371-9649-76313FCCAF8B'
    }
    const parameter = {
        ...baseParams,
        ...params
    }
    console.log(parameter);
    url +=
        '?' +
        Object.keys(parameter)
            .map(item => `${item}=${parameter[item]}`)
            .join('&')
    return url
}

<template>
  <page-header-wrapper>
    <template v-slot:content>
      <div class="page-header-content">
        <div class="avatar">
          <a-avatar shape="square" :src="house ? host + house.cover + THUMBNAIL : ''" />
        </div>
        <div class="content">
          <div class="content-title">{{ house ? house.name : '--' }}<span class="welcome-text"></span></div>
          <div>
            {{ house ? house.developerName : '--' }} | {{ house ? house.province : '--' }} -
            {{ house ? house.city : '--' }} - {{ house ? house.district : '--' }}
          </div>
        </div>
      </div>
    </template>
    <template v-slot:extraContent>
      <div class="extra-content">
        <div class="stat-item">
          <a-statistic title="楼盘类型" :value="house ? (house.type === 0 ? '住宅' : '商业') : '未设置'" />
        </div>
        <div class="stat-item">
          <a-statistic
            title="掌柜电话"
            :value="house ? (house.managerPhone ? phoneFormat(house.managerPhone.toString()) : '未设置') : '未设置'"
          />
        </div>
        <div class="stat-item">
          <a-statistic title="楼盘均价" :value="house ? (house.salePrice ? house.salePrice : '未设置') : '未设置'" />
        </div>
      </div>
    </template>

    <div class="page-header-index-wide">
      <a-card :bordered="false" :bodyStyle="{ padding: '16px 0', height: '100%' }" :style="{ height: '100%' }">
        <div class="account-settings-info-main" :class="{ mobile: isMobile }">
          <div class="account-settings-info-left">
            <a-menu
              :mode="isMobile ? 'horizontal' : 'horizontal'"
              :style="{ border: '0', width: isMobile ? '560px' : 'auto' }"
              :selectedKeys="selectedKeys"
              type="inner"
              @openChange="onOpenChange"
            >
              <template v-for="nav in navList">
                <a-menu-item v-if="btnPermission(nav.id)" :key="nav.key">
                  <router-link :to="{ path: nav.path }">
                    {{ nav.name }}
                  </router-link>
                </a-menu-item>
              </template>
            </a-menu>
          </div>
          <div class="account-settings-info-right">
            <div class="account-settings-info-title">
              <span>{{ $route.meta.title }}</span>
            </div>
            <route-view></route-view>
          </div>
        </div>
      </a-card>
    </div>
  </page-header-wrapper>
</template>

<script>
import { RouteView } from '@/layouts'
import { baseMixin } from '@/store/app-mixin'
import { houseGet } from '@/api/house'
import { THUMBNAIL } from '@/common/const'

export default {
  components: {
    RouteView
  },
  mixins: [baseMixin],
  data() {
    this.THUMBNAIL = THUMBNAIL
    return {
      id: null,
      house: null,
      // navList
      navList: [
        {
          name: '楼盘信息',
          id: 'residence_edit_info',
          path: '/residence/houses/base',
          key: '/residence/houses/base'
        },
        {
          name: '图片视频',
          id: 'residence_edit_media',
          path: '/residence/houses/img',
          key: '/residence/houses/img'
        },
        {
          name: 'VR看房',
          id: 'residence_edit_vr',
          path: '/residence/houses/vr',
          key: '/residence/houses/vr'
        },
        {
          name: '预售许可证',
          id: 'residence_edit_license',
          path: '/residence/houses/presell',
          key: '/residence/houses/presell'
        },
        {
          name: '户型维护',
          id: 'residence_edit_room',
          path: '/residence/houses/type',
          key: '/residence/houses/type'
        },
        {
          name: '地理位置',
          id: 'residence_edit_geo',
          path: '/residence/houses/map',
          key: '/residence/houses/map'
        },
        {
          name: '楼盘动态',
          id: 'residence_edit_news',
          path: '/residence/houses/dynamic',
          key: '/residence/houses/dynamic'
        },
        {
          name: '标签',
          id: 'residence_edit_tags',
          path: '/residence/houses/tag',
          key: '/residence/houses/tag'
        },
        {
          name: '代理商',
          id: 'residence_edit_agent',
          path: '/residence/houses/agent',
          key: '/residence/houses/agent'
        },
        {
          name: '掌柜',
          id: 'residence_edit_manager',
          path: '/residence/houses/manager',
          key: '/residence/houses/manager'
        }
      ],
      // horizontal  inline
      mode: 'inline',

      openKeys: [],
      selectedKeys: [],

      // cropper
      preview: {},
      option: {
        img: '/avatar2.jpg',
        info: true,
        size: 1,
        outputType: 'jpeg',
        canScale: false,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 180,
        autoCropHeight: 180,
        fixedBox: true,
        // 开启宽度和高度比例
        fixed: true,
        fixedNumber: [1, 1]
      },

      pageTitle: ''
    }
  },
  computed: {
    host() {
      return this.$store.state.oss.host
    }
  },
  mounted() {
    this.updateMenu()
    this.getHouseInfo(this.id)
  },
  created() {
    this.id = this.$route.query.id
    this.navList.forEach(item => {
      item.path = `${item.path}?id=${this.$route.query.id}&link=${this.$route.query.link}`
    })
  },
  methods: {
    onOpenChange(openKeys) {
      this.openKeys = openKeys
    },
    updateMenu() {
      const routes = this.$route.matched.concat()
      this.selectedKeys = [routes.pop().path]
    },
    getHouseInfo(id) {
      houseGet(id).then(res => {
        this.house = res
      })
    }
  },
  watch: {
    $route(val) {
      this.updateMenu()
    }
  }
}
</script>

<style lang="less" scoped>
@import './Workplace.less';
.account-settings-info-main {
  .account-settings-info-title {
    display: none;
  }
  .account-settings-info-right {
    padding: 0 !important;
  }
  width: 100%;
  display: flex;
  height: 100%;
  overflow: auto;

  &.mobile {
    display: block;

    .account-settings-info-left {
      border-right: unset;
      border-bottom: 1px solid #e8e8e8;
      width: 100%;
      height: 50px;
      overflow-x: auto;
      overflow-y: scroll;
    }
    .account-settings-info-right {
      padding: 20px 40px;
    }
  }

  .account-settings-info-left {
    border-right: 1px solid #e8e8e8;
    width: 224px;
  }

  .account-settings-info-right {
    flex: 1 1;
    padding: 8px 40px;

    .account-settings-info-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 12px;
    }
    .account-settings-info-view {
      padding-top: 12px;
    }
  }
}

// 布局
.account-settings-info-main {
  display: block;
  .account-settings-info-left {
    width: 100%;
  }
}
</style>
